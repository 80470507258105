<template>
  <div class="SiteList_I">
    <div class="headNavs">
      <div class="headTilte">站点列表</div>
      <div class="headDetails">说明：各个站点名称、链接、电话号码、状态及创建时间</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入站点名称">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"> 
        <el-table-column prop="site_id" label="ID" width="80" align="center"></el-table-column>
        <el-table-column prop="name" label="站点名称"  align="center"></el-table-column>
        <el-table-column prop="pinyin" label="拼英或简写"  align="center" min-width="120"></el-table-column>
        <el-table-column prop="url" label="链接"  align="center" width="300">
            <template #default="scope" >
             <div>
               <el-link class="tttyt" :href='scope.row.url' target="_blank"> <i class="el-icon-paperclip"></i>&nbsp;{{scope.row.url}}</el-link>
             </div>
          </template> 
        </el-table-column>
        <el-table-column prop="telephone" label="电话号码"  align="center"></el-table-column>
        <el-table-column prop="default_text" label="默认站点"  align="center"> </el-table-column>
        <el-table-column prop="status_text" label="状态"  align="center"></el-table-column>
        <el-table-column prop="weigh" label="权重"  align="center"></el-table-column>
        <el-table-column prop="createtime" label="创建时间"  align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(scope.row)"
              >编辑
              </el-button>
            </div>
            <div class="el_butto rere" >
              <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(scope.row)"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    
    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm">
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>站点名称：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.name" placeholder="请填写优惠券名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>拼英或简写：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.pinyin" placeholder="请填写拼英或简写"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>链接：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.url" placeholder="请填写链接"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>电话号码：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.telephone" placeholder="请输入电话号码"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>标题：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.title" placeholder="请填写标题"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>关键字：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.keywords" placeholder="请填写关键字"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>权重：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.weigh" placeholder="权重越高排序越靠前"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>简称：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.short_name" placeholder="请填写简称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>状态</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.status" placeholder="请选择是否启用" >
                <el-option v-for="(item, key) in TypeList" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item><el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>默认站点：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.default" placeholder="请选择是否启用" >
                <el-option v-for="(item, key) in StatusList" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>简介：</div>
            <div class="EditNewFormItemIpt">
              <el-input type="textarea" v-model="Row.description" placeholder="请填写站点详情"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">图片</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  ref="upload-weixin_qrcode"
                  list-type="picture-card"
                  :action="upload"
                  :headers="heads"
                  :limit="1"
                  :file-list="fileList"
                  :on-success="handleSuccess"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          
          
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios' 
export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      tableData:[],//总列表
      fileList:[],
      total: 0,
      // 分页
      page: {
        currentPage: 1,
        pageSize: 10,
      }, 
      // 打开方式
      TypeList: [
        "隐藏",
        "正常"
      ],
      StatusList: [
        "否",
        "是"
      ],
      // 列表数据
      Row: { 
        name: "",
        pinyin: "", 
        url: "",
        telephone:'',
        weixin_qrcode:'',
        short_name:'',
        title:'',
        keywords:'',
        description:'',
        default:'',
        status:'',
        weigh: "",
      },
      //搜索参数
      search: {
        keyword: "",
      },
      addPanel: false,
      editPanel: false, 
      editId: 0,
      titleMath:'',
    }
  },
  mounted() {
    var that = this
    that.loadTable(); 
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    
  },
  methods: { 
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/general/site/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    }, 
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if( e == false){
        that.search = {
          keyword: "",
          site_id: "",
        }
      }
      that.loadTable();
    },
    //新增
    BtnAdd() {
      var that = this
      if (that.addPanel) {
        axios({
          method: 'post',
          url: '/admin/general/site/add',
          data: {
            row : that.Row
          },
        }).then(function (res) {
          console.log(res.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.addPanel = false
            that.loadTable();
          }
        })
      }
    },
    // 创建打开
    showAdd() {
      var that = this
      that.addPanel = true;
      that.titleMath = '添加'
      that.Row = {
        name: "",
        pinyin: "", 
        url: "",
        telephone:'',
        weixin_qrcode:'',
        short_name:'',
        title:'',
        keywords:'',
        description:'',
        default:'',
        status:'',
        weigh: "",
      }; 
    },
    // 编辑
    BtnEdit() {
      var that = this
      if (that.editPanel) {
        axios({
          method: 'put',
          url: '/admin/general/site/edit',
          data: {
            id: that.editId,
            row : that.Row
          },
        }).then(function (res) {
          console.log(res.data.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editPanel = false
            that.loadTable();
          }
        })
      }
    },
    //获取编辑内容
    HandleEdit(data) {
      var that = this
      that.editPanel = true;
      that.titleMath = '编辑'
      that.editId = data.site_id
      axios.get('/admin/general/site/read', {   // 详情
        params: {
          id: data.site_id
        }
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
            that.Row = res.data.data.row,
            that.Row.name =res.data.data.row.name
            that.Row.pinyin =res.data.data.row.pinyin
            that.Row.url =res.data.data.row.url
            that.Row.telephone =res.data.data.row.telephone
            that.Row.weixin_qrcode =res.data.data.row.weixin_qrcode
            that.Row.short_name =res.data.data.row.short_name
            that.Row.title =res.data.data.row.title
            that.Row.keywords =res.data.data.row.keywords
            that.Row.description =res.data.data.row.description
            that.Row.weigh =res.data.data.row.weigh
            that.Row.status = parseInt(res.data.data.row.status)
            that.Row.default = parseInt(res.data.data.row.default)
            
            //图片
            let fileArr = [];
            let fileObj = {};
            fileObj.name = "file";
            fileObj.url = res.data.data.row.weixin_qrcode;
            fileArr.push(fileObj);
            that.fileList = fileArr
        }
      })
    },
    // 关闭
    Close() {
      var that = this
      that.editPanel = false;
      that.addPanel = false; 
      that.Row = {}
    },
    //删除
    HandleDel( data) {
      var that = this
      var arr = []
      //主键ID
      arr.push(data.site_id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/general/site/del',
          data: {
            ids: arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    //图片上传成功
    handleSuccess(e) {
      var that = this
      that.Row.weixin_qrcode = e.data.url
    },
    handleExceed  (files)  {
      this.$refs["upload-weixin_qrcode"].clearFiles()
      this.$refs["upload-weixin_qrcode"].handleStart(files[0])
      this.$refs["upload-weixin_qrcode"].submit();
    },
    handlePreview(file) {
      console.log(file);
      window.open(file.url);
    },
  }
}
</script>



